import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './styles.scss';
import logo from '../../assets/images/logo/logo.png';
import Button from '../button/index';
import menus from '../../pages/menu';

const Header = () => {
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300);
        });
        return () => {
            setScroll({});
        }
    }, []);

    const [menuActive, setMenuActive] = useState(false);
    const handleMenuActive = () => {
        setMenuActive(!menuActive);
    };

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: "smooth"
            });
            setMenuActive(false); // Cerrar el menú desplegable en dispositivos móviles
        }
    };

    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index);
    };

    return (
        <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
            <div className="container">
                <div id="site-header-inner">
                    <div className="header__logo">
                        <NavLink to="/"><img src={logo} alt="Bulliescoin" /></NavLink>
                    </div>
                    <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`} >
                        <ul id="menu-primary-menu" className="menu">
                            {menus.map((data, idx) => (
                                <li key={idx} onClick={() => handleDropdown(idx)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`}>
                                    <Link to="#" onClick={() => scrollToSection(data.name.toLowerCase())}>{data.name}</Link>
                                    {data.namesub && (
                                        <ul className="sub-menu">
                                            {data.namesub.map(submenu => (
                                                <li key={submenu.id} className="menu-item"><NavLink to="#" onClick={() => scrollToSection(submenu.sub.toLowerCase())}>{submenu.sub}</NavLink></li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </nav>
                    <Button title='X Twitter' path='https://twitter.com/bulliescoin' target='_blank' addclass='style1' />
                    <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>
                </div>
            </div>
        </header>
    );
}

export default Header;
