import {HelioCheckout} from '@heliofi/checkout-react'

const helioConfig = {
    /* paylinkId: "65f381db3bfaac00da0b85aa", */
    paylinkId: "6603a6a364d50b3ada87eeaa",
    network: "test",
    onSuccess: event => console.log(event),
    onError: event => console.log(event),
    onPending: event => console.log(event),
    onCancel: () => console.log("Cancelled payment"),
    onStartPayment: () => console.log("Starting payment"),

    theme: {
        themeMode: "light", // or "light"
        colors: {
            primaryButtonBackground: "#00e2a5",
            primaryButtonText: "#FFFFFF",
          },
        }
};

function BulliesPresale() {
    
        return <HelioCheckout config={helioConfig} />;
    
}

export default BulliesPresale;