import React from 'react';
import img from '../../assets/images/common/img_cta.png'
import { Link } from 'react-router-dom';
import { useState } from 'react';

function CTA(props) {

    const [dataTitle] = useState(
        {
            title: 'Disclaimer',
            subtitle: 'Warning $Bullies is a meme coin with no intrinsic value or expectation of financial return. There is no formal team or roadmap. The coin is completely useless and for entertainment purposes only.'
        }
    );

    // Dividir la cadena antes y después de "Warning" y aplicar el estilo alrededor de "Warning"
    const parts = dataTitle.subtitle.split('Warning');
    const warningElement = (
        <>
            {parts[0]}
            <span style={{ color: '#F8CC20' }}>Warning:</span>
            {parts[1]}
        </>
    );

    return (
        <section className="tf-section tf_CTA">
            <div className="container relative">
                <div className="overlay"></div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="tf-title left mt58" data-aos="fade-up" data-aos-duration="800">
                            <h2 className="title">{dataTitle.title}</h2>
                            <p className="sub">{warningElement}</p>
                            <div className="wrap-btn">
                                <Link to="/" className="tf-button style3">
                                    Explore
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="image_cta" data-aos="fade-left" data-aos-duration="1200">
                            <img className="move4" src={img} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CTA;
