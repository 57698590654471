import React from 'react';
import '../scss/component/_section.scss';
import Faqs from '../components/faqs';
import dataFaq from '../assets/fake-data/data-faqs'; 
import '../scss/component/_box.scss'; 
import '../scss/component/_tf-section.scss'; 
import '../scss/component/_reponsive.scss'; 
// import Banner from '../components/banner/banner_v1'; 
import dataBanner from '../assets/fake-data/data-banner';
import Project from '../components/project/project_v1';
import Banner3 from '../components/banner/banner_v3';  
// import Project2 from '../components/project/project_v2';  
import dataProject from '../assets/fake-data/data-project';
// import Project3 from '../components/project/project_v3';  
// import Project4 from '../components/project/project_v4';
// import Project6 from '../components/project/project_v6';
import Token from '../components/token';

import Team from '../components/team';
import Partner from '../components/partner';
import dataPartner from '../assets/fake-data/data-partner';
import CTA from '../components/cta';
import dataTeam from '../assets/fake-data/data-team';
import { Helmet } from 'react-helmet';


function HomeOne(props) {
 
    return (
        <div className='header-fixed main home1 counter-scroll'>
           <Helmet>
                <title>Bullies Meme Token on Solana</title>
                <meta name="description" content="From Zero to Heroe" />
            </Helmet>

           {<Banner3 data={dataBanner} />}
           {/*<Banner data={dataBanner} /> */}
           {/* {<Project6 data={dataProject} />} */}
           
           {<Project id="presale" data={dataProject} />}
           {<Token id="tokenomics" />}
           {/* {<Project2 data={dataProject}/>}
           {<Project3 data={dataProject}/>} */}  
            {/* <Project4 data={dataProject} /> */}

            <Team id="team" data={dataTeam} />

            <Partner data={dataPartner} />

            {<Faqs id="faqs" data={dataFaq} />} 

            <CTA />


        </div>
    );
}

export default HomeOne;