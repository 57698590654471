import React , { useState } from 'react';
import PropTypes from 'prop-types';

import { Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../styles.scss'; 
import BulliesPresale from '../../heliowidget';

Project.propTypes = {
    data: PropTypes.array,
};

function Project(props) {
    const {data} = props;

    const [dataTitle] = useState({
        title: 'PRESALE',
        title_2: ' coming soon'
    });

    return (
        <section className="tf-section project">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title" data-aos="fade-up" data-aos-duration="800">
                            <h2 className="title">
                                {dataTitle.title}<br className="show-destop"/> {dataTitle.title_2}
                            </h2>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="project-wrapper">
                            <div className="image-wrapper" data-aos="fade-in" data-aos-duration="1000">
                                <div className="slider-1">
                                    <Swiper 
                                        modules={[Pagination, A11y, Autoplay]} // Agregar Autoplay como módulo
                                        spaceBetween={0}
                                        slidesPerView={1}
                                        pagination={{ clickable: true }}
                                        autoplay={{ delay: 4000 }} // Configurar el retraso de autoplay (en milisegundos)
                                    >
                                        {data.slice(0,3).map(item => (
                                            <SwiperSlide key={item.id} item={item}>
                                                <img src={item.img} alt="Bullies" />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                            <div className="content-wrapper">
                                <div className="content_inner" data-aos="fade-left" data-aos-duration="1200">
                                    <div className="wrapper">
                                        <BulliesPresale />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Project;
